<template>
  <div>
    <Html dir="ltr" :lang="locale">
      <Head>
        <Link
          v-for="link in headLinkItems"
          :id="link.id"
          :key="link.id"
          :href="link.href"
          :rel="link.rel"
        />

        <Meta
          v-for="item in headMetaItems"
          :key="item.property"
          :content="item.content"
          :property="item.property"
        />
      </Head>
      <Body>
        <nuxt-layout>
          <nuxt-page />
        </nuxt-layout>

        <client-only>
          <app-dialog-handler :registry="dialogRegistry" />
          <app-toast-notification-handler />
          <lazy-promotion-code-handler v-if="$route.query.promotion_code" />
        </client-only>
      </Body>
    </Html>
  </div>
</template>

<script lang="ts" setup>
import { dialogRegistry } from "~/shared/dialog/dialog.registry";

const { $host } = useNuxtApp();
const { isProduction } = useEnvironment();
const { t, locale, availableLocales } = useI18n();

const DEFAULT_TITLE =
  "Pollen - Formation professionnelle et continue avec les meilleurs experts";

useSeoMeta({
  robots: isProduction ? "index" : "noindex",
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
  },
  description: t("app.seo.default_metadescription"),
  ogType: "website",
  ogSiteName: "Pollen",
  ogImage: `${$host}/images/brand/og-image.jpg`,
  twitterSite: "@hey_pollen",
});

useSchemaOrg([
  defineOrganization({
    name: "Pollen",
    logo: "/images/logo_full_color.svg",
  }),
  defineWebPage(),
]);

const route = useRoute();

watch(
  () => route.path,
  () => {
    document.documentElement.classList.remove("overflow-hidden");
  },
);

const headLinkItems = computed<
  {
    id: string;
    rel: string;
    href: string;
  }[]
>(() => {
  return [
    {
      id: "i18n-can",
      rel: "canonical",
      href: `${$host}${route.path}`,
    },
  ];
});

const headMetaItems = computed<
  {
    property: string;
    content: string;
  }[]
>(() => {
  const alternateLocales = availableLocales.filter(
    (item) => item !== locale.value,
  );

  return [
    ...alternateLocales.map((locale) => {
      return {
        property: "og:locale:alternate",
        content: locale,
      };
    }),
    {
      property: "og:locale",
      content: locale.value,
    },
  ];
});

useOnboardingAutonomousStart();
</script>
