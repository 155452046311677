<template>
  <app-page-section
    class="rounded-lg bg-primary-950 px-8 py-8 text-white md:py-24"
    :subtitle="{
      tag: 'h3',
      text: 'Pollen est certifié Qualiopi : obtenez un financement OPCO',
    }"
    :title="{
      tag: 'h2',
      text: 'Pollen, un organisme de formation professionnelle et continue',
    }"
  >
    <p class="mx-auto -mt-6 max-w-[1000px] text-balance text-center">
      Pollen réinvente la formation professionnelle aux côtés des meilleurs
      experts du marché, en poste dans les entreprises les plus innovantes. Nos
      sessions inter, intra entreprise et nos programmes de formations sur
      mesure sont certifiés Qualiopi. Vous pouvez demander à financer votre
      prochaine formation Pollen grâce au budget OPCO.
    </p>

    <template #prepend>
      <app-image alt="Qualiopi logo" src="/images/qualiopi_logo.svg" />
    </template>

    <template #append>
      <app-button
        class="w-full sm:w-auto"
        color="light"
        size="xl"
        to="/getting-financed"
        variant="outlined"
      >
        En savoir plus
      </app-button>
    </template>
  </app-page-section>
</template>
