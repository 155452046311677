<template>
  <div
    class="app-layout"
    :style="{
      '--layout-offset-top': hidden ? '0px' : undefined,
      '--launch-banner-height': !displayNewsBanner ? '0px' : undefined,
    }"
  >
    <div
      class="fixed z-50 w-full transition duration-navigation"
      :class="{
        '-translate-y-full': hidden,
      }"
    >
      <news-banner
        v-if="displayNewsBanner && currentNews"
        class="h-[--launch-banner-height] w-full"
        :news="currentNews"
        @close="acknowledgeNewsBanner"
      />

      <main-header
        class="h-[--top-nav-height]"
        :class="{
          'bg-white/95 backdrop-blur': hasStartedScroll,
        }"
        :no-navigation="noNavigation"
      />
    </div>

    <main class="min-h-screen pt-[--top-header-height]" data-cy-main-content>
      <slot />
    </main>

    <faq-section v-if="!noFaq" class="py-32" />

    <main-footer v-if="!noFooter" />
  </div>
</template>

<script setup lang="ts">
import FaqSection from "~/layouts/_includes/components/faq-section.vue";
import MainHeader from "~/layouts/_includes/components/main-header.vue";
import MainFooter from "~/layouts/_includes/main-footer.vue";
import NewsBanner from "~/layouts/_includes/news-banner.vue";

const hidden = ref(false);
const hasStartedScroll = ref(false);

if (process.client) {
  const { directions, y } = useScroll(document);

  watchEffect(() => {
    hasStartedScroll.value = y.value >= 80;

    if (directions.bottom && hasStartedScroll.value) {
      hidden.value = true;
    }

    if (directions.top) {
      hidden.value = false;
    }
  });
}

const route = useRoute();
const noFooter = computed(() => Boolean(route.meta.noFooter));
const noNavigation = computed(() => Boolean(route.meta.noNavigation));
const noFaq = computed(() => Boolean(route.meta.noFaq));

const { acknowledgeNewsBanner, displayNewsBanner, currentNews } =
  useNewsBanner();
</script>

<style scoped>
.app-layout {
  --top-nav-height: 60px;
  --launch-banner-height: 100px;
  --top-header-height: calc(
    var(--top-nav-height) + var(--launch-banner-height)
  );
  --layout-offset-top: var(--top-header-height);
}

@media screen(desktop-nav) {
  .app-layout {
    --top-nav-height: 80px;
  }
}

@media screen(md) {
  .app-layout {
    --launch-banner-height: 60px;
  }
}
</style>
