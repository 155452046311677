<template>
  <app-page-section
    v-if="data.length > 0"
    :title="{ tag: 'h3', text: $t('press.list_title') }"
  >
    <app-carousel :item-gap="0" :item-width="340" :items="data">
      <template #item="{ item }">
        <div class="h-full p-2">
          <nuxt-link class="block h-full" :href="item.url" target="_blank">
            <app-card
              class="flex h-full flex-col justify-between gap-4 p-4 transition-shadow hover:shadow-lg"
              outlined
            >
              <figure>
                <div class="relative overflow-hidden rounded">
                  <app-image
                    v-if="item.cover"
                    alt=""
                    class="w-full object-cover"
                    :height="220"
                    :src="item.cover"
                  />

                  <div
                    class="absolute bottom-0 grid h-1/2 w-full items-end bg-gradient-to-t from-black/80 p-4"
                  >
                    <div class="flex items-center gap-2">
                      <p class="text-sm font-semibold text-white">
                        {{ item.website }}
                      </p>
                    </div>
                  </div>
                </div>

                <figcaption class="mt-4">
                  <p class="mb-1 line-clamp-2 font-semibold">
                    {{ item.title }}
                  </p>
                </figcaption>
              </figure>

              <app-button block color="dark" tag="span">
                {{ $t("press.item_cta_label", { name: item.website }) }}
              </app-button>
            </app-card>
          </nuxt-link>
        </div>
      </template>
    </app-carousel>
  </app-page-section>
</template>

<script lang="ts" setup>
const { data } = useLazyFetch("/api/press", {
  default: () => [],
  server: false,
});
</script>
