export function isMatchingPath(path: string, pattern: string) {
  const isNegation = pattern.startsWith("!");

  const regexp = new RegExp(
    pattern
      .replace(/^!/, "")
      .split("/")
      .map((part) => {
        if (part === "*") return "[^/]+";
        if (part === "**") return ".*";

        return part;
      })
      .join("/"),
  );

  const result = regexp.exec(path);

  if (isNegation) {
    return result?.[0] !== path;
  }

  return result?.[0] === path;
}
