<template>
  <app-dialog
    v-model="model"
    subtitle="Formations inter et intra entreprise, programmes sur mesure"
    title="Le détail de nos offres de formation"
    :width="1200"
  >
    <div
      class="h-[calc(100vh-200px)] max-h-[1100px] overflow-auto rounded-r-lg"
    >
      <table class="w-full">
        <colgroup>
          <col />
          <col v-for="offer in list" :key="offer.id" width="25%" />
        </colgroup>

        <thead class="">
          <tr>
            <th :class="headerClasses" />

            <th v-for="offer in list" :key="offer.id" :class="headerClasses">
              <div
                class="border-b-2 border-primary/10 p-4"
                :class="[offer.classes]"
              >
                <p class="mb-1 text-xl font-semibold">
                  {{ offer.title }}
                </p>
                <p
                  class="text-balance text-sm"
                  :class="{ 'text-subtle': offer.id === 'talent' }"
                >
                  {{ offer.subtitle }}
                </p>
                <growth-contact-button
                  class="mt-3"
                  :color="offer.id === 'academy' ? 'accent' : 'primary'"
                  origin="enterprise"
                  :origin_page_action="`offer_comparison_dialog_${offer.id}_cta`"
                />
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-for="section in sections" :key="section.title">
            <tr>
              <th class="pb-3 pt-4 text-left text-xl font-semibold">
                {{ section.title }}
              </th>
              <td
                v-for="offer in list"
                :key="offer.id"
                :class="offer.classes"
              />
            </tr>

            <tr v-for="line in section.lines" :key="line.label" class="group">
              <td class="py-1 transition group-hover:bg-gray-50">
                {{ line.label }}
              </td>
              <td
                class="text-center transition group-hover:bg-gray-50"
                :class="talent?.classes"
              >
                <p v-if="line.talent === null">Sur condition</p>
                <app-icon
                  v-else-if="line.talent"
                  class="relative bottom-1"
                  icon="ph:check"
                />
              </td>
              <td
                class="text-center transition group-hover:bg-primary-100"
                :class="team?.classes"
              >
                <p v-if="line.team === null">Sur condition</p>
                <app-icon
                  v-else-if="line.team"
                  class="relative bottom-1"
                  icon="ph:check"
                />
              </td>
              <td
                class="text-center transition group-hover:bg-primary-950"
                :class="academy?.classes"
              >
                <p v-if="line.academy === null">Sur condition</p>
                <app-icon
                  v-else-if="line.academy"
                  class="relative bottom-1"
                  icon="ph:check"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </app-dialog>
</template>

<script lang="ts" setup>
import { useOfferDetails } from "~/business-areas/offer/composables/offer-details.hook";

const model = defineModel({ default: false });

const { list, getOfferById } = useOfferList();

const talent = getOfferById("talent");
const team = getOfferById("team");
const academy = getOfferById("academy");

const { sections } = useOfferDetails();

const headerClasses = "font-normal sticky top-0 z-[1] bg-white p-0";
</script>
