<template>
  <div class="grid min-h-screen lg:grid-rows-[auto_1fr_auto]">
    <div>
      <app-page-wrapper
        class="flex h-[60px] items-center justify-between md:h-[80px]"
      >
        <nuxt-link to="/">
          <brand-navigation-logo />
        </nuxt-link>

        <app-button-back v-if="previous" :to="previous" />
      </app-page-wrapper>
    </div>

    <app-page-wrapper class="grid items-center !px-6">
      <section
        class="grid gap-4 rounded-lg bg-primary-900 p-6 md:gap-8 md:p-8 lg:grid-cols-[1fr_450px] xl:grid-cols-[1fr_550px]"
      >
        <component :is="component" class="text-white" :metadata="metadata" />

        <div class="mb-6 rounded bg-white p-4 sm:p-6 lg:mb-0 lg:min-h-[500px]">
          <contact-page-form
            :options="options"
            v-bind="metadata"
            :previous="previous"
            :redirect="redirect"
            @submit="onSubmit"
          />
        </div>
      </section>
    </app-page-wrapper>

    <div>
      <app-page-wrapper class="py-6">
        <brand-customer-logos-banner class="my-12 hidden tall:block" />

        <p class="text-center text-sm">
          {{ copyrightMention }}
        </p>
      </app-page-wrapper>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCopyrightMention } from "~/layouts/_includes/composables/main-footer.hook";
import ContactPageForm from "~/pages/contact/_includes/components/contact-page-form.vue";
import { useContactPageContent } from "~/pages/contact/_includes/composables/contact-page-content.hook";
import type { ContactFlowMetadata } from "~~/model/analytics.model";

definePageMeta({
  layout: false,
});

const { t } = useI18n();

useSeoMeta({
  title: t("growth.contact_page.title"),
});

const copyrightMention = useCopyrightMention();

const route = useRoute();

const metadata = computed<ContactFlowMetadata>(() => {
  const query = route.query as ContactFlowMetadata;

  return {
    origin: query.origin,
    origin_page_id: query.origin_page_id,
    origin_page_action: query.origin_page_action,
  };
});

const { component, options } = useContactPageContent(metadata);

const previous = usePreviousRoute();

const redirect = computed(() => {
  if (route.query.redirect) return route.query.redirect;
  if (route.name === previous?.name) return "/";

  return previous?.fullPath ?? "/";
});

function onSubmit() {
  if (
    route.query.confirmation_toast &&
    route.query.confirmation_toast !== "true"
  )
    return;

  showSuccessToastNotification(t("growth.contact_page.confirmation.title"), {
    subtitle: t("growth.contact_page.confirmation.subtitle"),
    icon: "ph:paper-plane-tilt",
  });
}
</script>
