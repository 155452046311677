<template>
  <app-page-wrapper
    class="!max-w-[calc(theme(screens.3xl)+theme(spacing.24))] !px-4 sm:!px-6 md:!px-10"
  >
    <section class="grid gap-3 lg:grid-cols-[60%_1fr]">
      <div
        class="rounded-xl bg-primary-900 p-6 pb-[25vw] text-white md:px-12 md:pt-12 lg:pb-12"
      >
        <header>
          <component
            :is="titleDefinition.tag"
            class="text-balance font-title text-3xl/normal md:text-5xl/normal 3xl:text-6xl/snug"
          >
            {{ titleDefinition.text }}
          </component>

          <div v-if="subtitle || $slots.subtitle" class="mt-3 sm:text-lg">
            <slot name="subtitle">
              <component :is="subtitleDefinition.tag">
                {{ subtitleDefinition.text }}
              </component>
            </slot>
          </div>
        </header>

        <div v-if="$slots.default" class="mt-6">
          <slot />
        </div>

        <footer
          v-if="$slots.cta"
          class="mt-10 [&>[data-app-button]:only-child]:w-full sm:[&>[data-app-button]:only-child]:w-auto"
        >
          <slot name="cta" />
        </footer>
      </div>

      <div
        class="relative mx-6 -mt-[calc(25vw-theme(spacing.6))] aspect-video md:mx-12 lg:mx-0 lg:mt-0 lg:aspect-auto"
      >
        <slot name="aside">
          <app-image
            v-if="cover"
            alt=""
            class="absolute h-full w-full rounded-xl object-cover"
            loading="eager"
            :src="cover"
            :width="1000"
          />
        </slot>
      </div>
    </section>
  </app-page-wrapper>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "~/core/i18n/i18n.model";

const properties = defineProps<{
  title:
    | I18nMessage
    | { tag: `h${1 | 2 | 3 | 4 | 5 | 6}` | string; text?: I18nMessage };
  subtitle?:
    | I18nMessage
    | { tag: `h${1 | 2 | 3 | 4 | 5 | 6}` | string; text?: I18nMessage };
  cover?: string;
}>();

const titleDefinition = computed(() => {
  if (typeof properties.title === "object") return properties.title;

  return {
    tag: "h1",
    text: properties.title,
  };
});

const subtitleDefinition = computed(() => {
  if (typeof properties.subtitle === "object") return properties.subtitle;

  return {
    tag: "p",
    text: properties.subtitle,
  };
});
</script>
