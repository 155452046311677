<template>
  <section :id="id" class="scroll-m-[180px]">
    <header class="mb-8 flex items-start justify-between gap-4">
      <div>
        <component
          :is="titleWrapper"
          v-if="title || $slots.title"
          class="group"
          :to="to"
        >
          <component :is="titleDefinition.tag" class="text-2xl font-extrabold">
            <slot name="title">
              {{ titleDefinition.text }}
            </slot>

            <slot name="append-title" />

            <app-icon
              v-if="to"
              class="ml-2 scale-90 transition group-hover:scale-100"
              icon="ph:arrow-circle-up-right"
            />
          </component>
        </component>

        <component
          :is="subtitleDefinition.tag"
          v-if="subtitle"
          class="mt-1 text-subtle"
        >
          {{ subtitleDefinition.text }}
        </component>
      </div>

      <div v-if="$slots['append-header']" class="mt-1">
        <slot name="append-header" />
      </div>
    </header>

    <slot />
  </section>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from "#vue-router";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const properties = defineProps<{
  title?:
    | I18nMessage
    | { tag: `h${1 | 2 | 3 | 4 | 5 | 6}` | string; text?: I18nMessage };
  subtitle?:
    | I18nMessage
    | { tag: `h${1 | 2 | 3 | 4 | 5 | 6}` | string; text?: I18nMessage };
  id?: string;
  to?: RouteLocationRaw;
}>();

const titleWrapper = computed(() =>
  properties.to ? resolveComponent("nuxt-link") : "div",
);

const titleDefinition = computed(() => {
  if (typeof properties.title === "object") return properties.title;

  return {
    tag: "h2",
    text: properties.title,
  };
});

const subtitleDefinition = computed(() => {
  if (typeof properties.subtitle === "object") return properties.subtitle;

  return {
    tag: "p",
    text: properties.subtitle,
  };
});
</script>
