<template>
  <div
    class="flex flex-col gap-8 lg:grid lg:grid-cols-[60%_auto_1fr] xl:gap-12"
  >
    <app-page-subsection title="Événements" :to="{ name: 'events' }">
      <event-card-carousel class="-mt-2" :list="data" />
    </app-page-subsection>

    <div class="hidden pt-16 lg:block">
      <app-divider class="!h-full" vertical />
    </div>

    <app-page-subsection title="Derniers articles" :to="{ name: 'blog' }">
      <ul class="grid gap-4">
        <li v-for="post in displayedPosts.slice(0, 6)" :key="post.id">
          <app-card-link-background
            v-if="post.pinned"
            :to="{ name: 'blog-id', params: { id: post.id } }"
          >
            <app-card class="flex items-center overflow-hidden" outlined>
              <app-image
                v-if="post.og_image"
                :alt="post.title"
                class="aspect-square object-cover"
                :height="100"
                :src="post.og_image"
                :width="100"
              />
              <p class="line-clamp-2 px-4 font-semibold">
                {{ post.title }}
              </p>
            </app-card>
          </app-card-link-background>

          <blog-post-list-item v-else :post="post" />
        </li>
      </ul>
    </app-page-subsection>
  </div>
</template>

<script lang="ts" setup>
import EventCardCarousel from "~/business-areas/event/components/event-card-carousel.vue";

const { data } = useEventList();

const { data: posts } = useLazyFetch("/api/blog/posts", {
  server: false,
  query: {
    searchable: true,
    limit: 10,
  },
  default: () => [],
});

const displayedPosts = computed(() => {
  return (
    posts.value?.sort((post) => {
      if (post.pinned) return -1;

      return 0;
    }) ?? []
  );
});
</script>
