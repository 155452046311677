<template>
  <app-page-wrapper
    class="text-center"
    :style="{ maxWidth: `${CAROUSEL_MAX_WIDTH_IN_PX}px` }"
  >
    <p class="balance mb-8 text-lg" v-html="title" />

    <div
      ref="wrapper"
      class="relative h-[--height] overflow-hidden"
      :style="{
        '--height': `${logoHeight}px`,
      }"
    >
      <ul
        class="carousel absolute flex items-center gap-10"
        :class="{
          paused: !targetIsVisible,
        }"
        :style="carouselStyle"
      >
        <li
          v-for="(item, index) in [...list, ...list]"
          :key="`${item.name}_${index}`"
          :aria-hidden="index >= list.length"
        >
          <app-image
            :alt="`${item.name} logo`"
            :class="logoClasses"
            :height="logoHeight"
            loading="eager"
            :src="item.src"
          />
        </li>
      </ul>

      <div
        class="absolute left-0 h-full w-12 bg-gradient-to-r from-white"
        :class="overlayClasses"
      />
      <div
        class="absolute right-0 h-full w-12 bg-gradient-to-l from-white"
        :class="overlayClasses"
      />
    </div>
  </app-page-wrapper>
</template>

<script lang="ts" setup>
import capitalize from "lodash/capitalize";

import type { BrandLogoCarouselShareableProps } from "~/business-areas/brand/brand.model";
import type { I18nMessage } from "~/core/i18n/i18n.model";

const CAROUSEL_MAX_WIDTH_IN_PX = 1500;
const CAROUSEL_MAX_LOGO_SHOWN = 8;

const properties = withDefaults(
  defineProps<
    {
      title: I18nMessage;
      items: string[];
      logoHeight?: number;
    } & BrandLogoCarouselShareableProps
  >(),
  {
    logoHeight: 36,
  },
);

const list = computed(() => {
  return properties.items.map((item) => {
    return {
      src: `/images/logos/black-and-white/${item}.svg`,
      name: capitalize(item),
    };
  });
});

const { width } = useWindowSize();
const carouselStyle = computed(() => {
  return {
    width: `${
      (((properties.items.length * properties.logoHeight * 3) /
        CAROUSEL_MAX_LOGO_SHOWN) *
        2 *
        CAROUSEL_MAX_WIDTH_IN_PX) /
      Math.min(CAROUSEL_MAX_WIDTH_IN_PX, width.value)
    }%`,
    animationDuration: `${
      (properties.items.length * 60) / CAROUSEL_MAX_LOGO_SHOWN
    }s`,
  };
});

const wrapper = templateRef<HTMLElement>("wrapper");
const targetIsVisible = useElementVisibility(wrapper);
</script>

<style scoped>
@keyframes carousel {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.carousel {
  animation-name: carousel;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &.paused {
    animation-play-state: paused;
  }
}
</style>
