<template>
  <app-card-link-background
    class="!rounded-lg"
    :class="{
      '!ring-deco2-50': event.is_upcoming,
    }"
    :to="event._links.page"
  >
    <app-card
      class="relative grid h-full grid-rows-[auto_1fr] overflow-hidden rounded-lg"
      :class="{
        '!bg-deco2-50': event.is_upcoming,
        '-sm-grid-rows-1 -sm:grid-cols-[110px_1fr] -sm:gap-4 -sm:rounded-none -sm:!bg-transparent -xs:grid-cols-[80px_1fr]':
          mobileLayout,
      }"
      :style="{
        width: width ? `${width}px` : undefined,
      }"
      tag="section"
    >
      <app-chip
        v-if="event.is_upcoming"
        class="absolute left-4 top-4 bg-deco2 text-black"
        :class="{ '-sm:left-2 -sm:top-20 -xs:top-12': mobileLayout }"
        size="small"
      >
        {{ formattedDate }}
      </app-chip>

      <app-image
        :alt="event.course.teacher.full_name!"
        class="aspect-video w-full rounded-br-[60px] object-cover"
        :class="{ '-sm:hidden': mobileLayout }"
        :src="event.course.teacher.cover_url ?? event.course.teacher.avatar_url"
      />

      <app-image
        v-if="mobileLayout"
        :alt="event.course.teacher.full_name!"
        class="aspect-square rounded-lg object-cover sm:hidden"
        :src="event.course.teacher.avatar_url"
        :width="300"
      />

      <div class="flex flex-col p-4" :class="{ '-sm:p-0': mobileLayout }">
        <dl
          v-if="information.length > 0"
          class="mb-2 flex flex-wrap items-center gap-x-3 gap-y-1 text-xs font-semibold"
          :class="{ '-sm:order-4 -sm:mt-3': mobileLayout }"
        >
          <div v-for="item in information" :key="item.label">
            <dt class="sr-only">
              {{ item.label }}
            </dt>
            <dd class="flex items-center gap-1">
              <app-icon :icon="item.icon" :size="16" />
              {{ item.value }}
            </dd>
          </div>
        </dl>

        <div class="flex-auto">
          <h3
            class="mb-1 line-clamp-2 font-title text-xl"
            :class="{
              '-sm:font-sans -sm:text-lg/snug -sm:!font-bold': mobileLayout,
            }"
          >
            {{ event.course.title }}
          </h3>

          <p
            class="text-lg font-semibold"
            :class="{
              '-sm:text-sm -sm:font-normal -sm:text-subtle': mobileLayout,
            }"
          >
            {{ event.course.teacher.full_name }}
          </p>

          <p :class="{ '-sm:hidden': mobileLayout }">
            {{
              event.course.teacher.description_short || event.course.teacher.job
            }}
          </p>

          <teacher-companies-logos
            class="mt-2"
            :companies="event.course.teacher.companies"
            :height="20"
            :max-width="`${100 / event.course.teacher.companies.length - 5}%`"
            variant="flat"
          />
        </div>

        <app-button
          block
          class="mt-6"
          :class="{
            '!bg-deco2 !text-black': event.is_upcoming,
            '-sm:!hidden': mobileLayout,
          }"
          :color="event.has_replay ? 'dark' : undefined"
          :icon="event.has_replay ? 'ph:video' : undefined"
          origin="events"
          size="lg"
          tag="span"
          :variant="event.has_replay ? 'outlined' : undefined"
        >
          <template v-if="event.is_upcoming">
            S'inscrire gratuitement
          </template>
          <template v-else-if="event.has_replay"> Voir le replay </template>
        </app-button>
      </div>
    </app-card>
  </app-card-link-background>
</template>

<script lang="ts" setup>
import { isThisYear } from "date-fns";

import type { EventListView } from "~/business-areas/event/composables/event-list.hook";
import {
  type CarouselProvidedState,
  CarouselProvideKey,
} from "~/shared/carousel/carousel.model";

const properties = defineProps<{
  event: EventListView;
  mobileLayout?: boolean;
}>();

const width = computed(
  () =>
    inject<CarouselProvidedState | null>(CarouselProvideKey, null)?.itemWidth,
);

const formattedDate = computed(() => {
  const isEventThisYear = isThisYear(new Date(properties.event.start_date!));

  return useI18nDate(properties.event.start_date!, {
    weekday: isEventThisYear ? "short" : undefined,
    day: "numeric",
    month: "short",
    year: isEventThisYear ? undefined : "numeric",
  }).value;
});

const information = computed<{ label: string; value: string; icon: string }[]>(
  () => {
    if (properties.event.is_upcoming) {
      return [
        {
          label: "Lieu",
          value: properties.event.place
            ? `${properties.event.place.name}, ${properties.event.place.address.city} ${properties.event.place.address.zip_code}`
            : "En ligne",
          icon: properties.event.is_onsite ? "ph:map-pin-area" : "ph:monitor",
        },
      ];
    }

    if (properties.event.has_replay) {
      return [
        {
          label: "Document",
          value: "Replay dispo.",
          icon: "ph:repeat",
        },
      ];
    }

    return [];
  },
);
</script>
