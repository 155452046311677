<template>
  <section class="flex flex-col gap-10 md:gap-16">
    <div v-if="$slots.prepend" class="flex justify-center">
      <slot name="prepend" />
    </div>

    <header class="balance text-center">
      <slot name="header">
        <component
          :is="titleDefinition.tag"
          class="m-auto max-w-[900px] font-title text-2xl/normal md:text-3xl/normal"
        >
          <slot name="title">
            {{ titleDefinition.text }}
          </slot>
        </component>

        <div v-if="subtitle || $slots.subtitle" class="mt-4 text-lg">
          <slot name="subtitle">
            <component :is="subtitleDefinition.tag">
              {{ subtitleDefinition.text }}
            </component>
          </slot>
        </div>
      </slot>
    </header>

    <div v-if="$slots.default">
      <slot />
    </div>

    <div
      v-if="$slots.append"
      class="flex justify-center [&>[data-app-button]:only-child]:w-full sm:[&>[data-app-button]:only-child]:w-auto"
    >
      <slot name="append" />
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { I18nMessage } from "~/core/i18n/i18n.model";

const properties = defineProps<{
  title?:
    | I18nMessage
    | { tag: `h${1 | 2 | 3 | 4 | 5 | 6}` | string; text?: I18nMessage };
  subtitle?:
    | I18nMessage
    | { tag: `h${1 | 2 | 3 | 4 | 5 | 6}` | string; text?: I18nMessage };
}>();

const titleDefinition = computed(() => {
  if (typeof properties.title === "object") return properties.title;

  return {
    tag: "h2",
    text: properties.title,
  };
});

const subtitleDefinition = computed(() => {
  if (typeof properties.subtitle === "object") return properties.subtitle;

  return {
    tag: "p",
    text: properties.subtitle,
  };
});
</script>
