import { isFuture } from "date-fns";

import type { RouteLocationRaw } from "#vue-router";
import type { EventListApiView } from "~~/model/event.model";

export type EventFilters = {
  exclude?: DatabaseTable<"course_sessions">["id"][];
};

export type EventListView = EventListApiView & {
  is_upcoming: boolean;
  _links: {
    page: RouteLocationRaw;
  };
};

export function useEventList(filters?: EventFilters) {
  const cache = useEventCache();

  return useLazyFetch("/api/events", {
    query: filters,
    transform(data) {
      const views =
        data?.map((event) => {
          const view: EventListView = {
            ...event,
            is_upcoming: isFuture(new Date(event.start_date!)),
            _links: {
              page: {
                name: "events-slug",
                params: {
                  slug: event.slug,
                },
              },
            },
          };
          return view;
        }) ?? [];

      const upcoming = views
        .filter((view) => view.is_upcoming)
        .sort((a, b) => {
          if (a.start_date! > b.start_date!) return 1;

          return -1;
        });

      const past = views
        .filter((view) => !view.is_upcoming)
        .sort((a, b) => {
          if (a.start_date! < b.start_date!) return 1;

          return -1;
        });

      cache.setMultiple(views);

      return [...upcoming, ...past];
    },
    default: () => [],
  });
}
