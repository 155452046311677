<template>
  <div>
    <transition-group
      :class="gridClasses"
      enter-active-class="transition"
      enter-from-class="opacity-0 translate-y-1"
      leave-active-class="transition"
      leave-to-class="opacity-0 translate-y-1"
      move-class="transition"
      tag="ul"
    >
      <li v-for="course in list" :key="course.slug">
        <course-card
          :course="course"
          mobile-layout
          :show-next-session="showNextSession"
          @click="onCardClick(course)"
        />
      </li>
    </transition-group>

    <div v-show="loading" aria-hidden="true" :class="gridClasses">
      <course-card-placeholder
        v-for="index in 8"
        :key="index"
        skeleton-class="bg-primary-100"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CourseListView } from "~/business-areas/course/composables/course-list.hook";
import {
  toAnalyticsCourseView,
  type TrackingPlanModel,
} from "~~/model/analytics.model";

const properties = defineProps<{
  list: CourseListView[];
  id: TrackingPlanModel["course_clicked"]["origin"];
  loading?: boolean;
  showNextSession?: boolean;
}>();

const { $analytics } = useNuxtApp();
const onCardClick = (course: CourseListView) =>
  $analytics.track("course_clicked", {
    origin: properties.id,
    course: toAnalyticsCourseView(course),
  });

const gridClasses =
  "grid gap-4 gap-y-7 sm:gap-y-4  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5";
</script>
