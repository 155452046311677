<template>
  <div class="@container">
    <div class="m-auto hidden w-full max-w-[600px] @md:block">
      <brand-nps-slider />
    </div>

    <app-page-section
      :title="{ tag: 'h3', text: $t('brand.nps_banner.title', { nps: '9,6' }) }"
    >
      <app-image
        alt=""
        class="-mb-[90px] rounded-lg object-cover object-[center_25%] lg:-mb-[220px] lg:aspect-[2.4]"
        src="https://res.cloudinary.com/dfdgwwkpd/image/upload/v1712830462/brand/enterprise/2ce22d8170e0378a38bdaaef42bb7d1d_eo68ed.jpg"
      />

      <brand-testimonial-card-list :list="data" />

      <template v-if="$slots.append" #append>
        <slot name="append" />
      </template>
    </app-page-section>
  </div>
</template>

<script lang="ts" setup>
import { useTestimonialList } from "~/business-areas/brand/composables/testmonial-list.hook";

const { data } = useTestimonialList({ page: "home" });
</script>
